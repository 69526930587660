import React, { useState,useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import logo from '../assets/IBBUL-logo.jpg';
import { UserContext } from '../context/UserContext';
import axios from 'axios';

const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isUsersMenuOpen, setIsUsersMenuOpen] = useState(false); // Default to collapsed
  const location = useLocation(); // Get current route path

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleUsersMenu = () => {
    setIsUsersMenuOpen(!isUsersMenuOpen);
  };

  const isActive = (path) => location.pathname === path ? 'active' : ''; // Function to check active menu item

  const { user, logout } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      // Call backend to log out
      //await axios.post('localhost/api/logout');

      // Clear user from context
      logout();

      // Optionally clear the token from local storage
      //localStorage.removeItem('authToken');

      // Redirect to login page
      navigate('/');
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  return (
    <nav className={`sidebar bg-light p-3 shadow ${isSidebarOpen ? 'sidebar-expanded' : 'sidebar-collapsed'}`} style={{ width: isSidebarOpen ? '250px' : '80px' }}>
      <div className="sidebar-header d-flex flex-column align-items-center">
        {/* <img src={logo} alt="Logo" className={`sidebar-logo ${!isSidebarOpen ? 'd-none' : ''}`} /> */}
        Logo
        <button className="btn btn-link text-success d-md-none ms-auto mt-3" onClick={toggleSidebar}>
          <i className={`bi ${isSidebarOpen ? 'bi-caret-left-fill' : 'bi-caret-right-fill'}`}></i>
        </button>
      </div>
      
      <ul className="nav flex-column mt-3">
      <li className="nav-item">
          <Link className={`nav-link text-success ${isActive('/dashboard')}`} to="/dashboard">
            <i className="bi bi-people"></i>
            <span className={`ms-2 ${!isSidebarOpen ? 'd-none' : ''}`}>Dashboard</span>
          </Link>
        </li>
        <li className="nav-item">
          <button className="nav-link text-success d-flex align-items-center" onClick={toggleUsersMenu}>
            <i className="bi bi-person"></i>
            <span className={`ms-2 ${!isSidebarOpen ? 'd-none' : ''}`}>Manage Users</span>
            <i className={`bi ${isUsersMenuOpen ? 'bi-chevron-up' : 'bi-chevron-down'} ms-auto`}></i>
          </button>
          <ul className={`nav flex-column ms-3 ${!isUsersMenuOpen ? 'd-none' : ''}`}>
            
            <li className="nav-item">
              <Link className={`nav-link text-success ${isActive('/users/view')}`} to="/users/view">
                <i className="bi bi-person-lines-fill"></i>
                <span className="ms-2">View Users</span>
              </Link>
            </li>

           

          </ul>
        </li>
        <li className="nav-item">
          <Link className={`nav-link text-success ${isActive('/enrollments')}`} to="/enrollments">
            <i className="bi bi-people"></i>
            <span className={`ms-2 ${!isSidebarOpen ? 'd-none' : ''}`}>Enrollments</span>
          </Link>
        </li>
      
        <hr />
        <li className="nav-item">
          <Link className={`nav-link text-success ${isActive('/settings')}`} to="/settings">
            <i className="bi bi-gear"></i>
            <span className={`ms-2 ${!isSidebarOpen ? 'd-none' : ''}`}>Settings</span>
          </Link>
        </li>
        <li className="nav-item">
          <a className='nav-link text-success' to="#" onClick={handleLogout}>
            <i className="bi bi-box-arrow-right"></i>
            <span className={`ms-2 ${!isSidebarOpen ? 'd-none' : ''}`}>Logout</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
