import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'; // Ensure the correct path to App.js
import { UserProvider } from './context/UserContext'; // Ensure correct path for UserProvider

ReactDOM.render(
  <UserProvider>
    <App />
  </UserProvider>,
  document.getElementById('root')
);
