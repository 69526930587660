// src/Router.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import Enrollments from "./pages/Dashboard/Enrollments";
import NewEnrollments from "./pages/Enrollment/Enrollment";
import PrivateRoute from "./components/PrivateRoute"; // Import PrivateRoute
import ViewUser from "./pages/users/ViewUsers";
import EditUser from './pages/users/EditUser';
import Verification from './pages/Enrollment/Verification'
import Start from './pages/Enrollment/Start'
import Settings from './pages/users/Settings'
import EditApplication from "./pages/Enrollment/EditApplication";


const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/admin/login" element={<Login />} />

        {/* Protecting the dashboard and enrollment routes */}
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/enrollments"
          element={
            <PrivateRoute>
              <Enrollments />
            </PrivateRoute>
          }
        />
        {/* <Route path="/" element={<NewEnrollments />} /> */}
        <Route path="/" element={<Start />} />
        <Route path="/users/view" element={<ViewUser />} />
       

        <Route path="/settings" element={<Settings />} /> 

        <Route path="/edit-application" element={<EditApplication />} /> 

      
      </Routes>
    </Router>
  );
};

export default AppRouter;
