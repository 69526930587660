// UserModal.js
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';

const UserModal = ({ showModal, onClose, userData, onSave }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('user');

  useEffect(() => {
    if (userData) {
      setName(userData.name);
      setEmail(userData.email);
      setRole(userData.role);
    } else {
      setName('');
      setEmail('');
      setPassword('');
      setRole('user');
    }
  }, [userData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const apiUrl = process.env.REACT_APP_API_URL;
    
    try {
      if (userData) {
        // Editing user
        await axios.put(`${apiUrl}/users/${userData.id}`, { name, email, password, role }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        Swal.fire('Success', 'User updated successfully', 'success');
      } else {
        // Adding user
        await axios.post(`${apiUrl}/users`, { name, email, password, role }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        Swal.fire('Success', 'User added successfully', 'success');
      }
      onSave(); // Refresh the users list
      onClose(); // Close the modal
    } catch (error) {
      Swal.fire('Error', 'Failed to save user', 'error');
    }
  };

  return (
    <div className={`modal ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{userData ? 'Edit User' : 'Add User'}</h5>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label">Name</label>
                <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} required />
              </div>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} required />
              </div>
              {!userData && ( // Show password input only when adding a user
                <div className="mb-3">
                  <label className="form-label">Password</label>
                  <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </div>
              )}
              <div className="mb-3">
                <label className="form-label">Role</label>
                <select className="form-select" value={role} onChange={(e) => setRole(e.target.value)}>
                 
                  <option value="user">User</option>
                </select>
              </div>
              <button type="submit" className="btn btn-success">{userData ? 'Update' : 'Add'} User</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserModal;
