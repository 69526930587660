import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import axios from 'axios';
import Swal from 'sweetalert2';  

const DashboardNavbar = () => {
  const { user, token, logout } = useContext(UserContext);  
  const navigate = useNavigate();

  const handleLogout = async () => {
    Swal.fire({
      title: "Logging out...",
      text: "Please wait while we log you out.",
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      // Send logout request to backend with Authorization token
      await axios.post(
        'http://127.0.0.1:8000/api/logout',
        {},  // No body is needed for the logout request
        {
          headers: {
            Authorization: `Bearer ${token}`,  // Attach the token for authentication
          },
        }
      );

      // Clear user from context
      logout();

      // Optionally clear the token from local storage
      localStorage.removeItem('authToken');

      // Redirect to login page
      navigate('/');
      
      Swal.fire("Logged out!", "You have successfully logged out.", "success");
    } catch (error) {
      console.error('Logout failed', error);
      Swal.fire("Error", "Failed to log out. Please try again.", "error");
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light shadow-sm">
      <div className="container-fluid">
        <Link className="navbar-brand text-success" to="/dashboard">
          MyApp
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="d-flex align-items-center ms-auto">
            <div className="dropdown">
              <button
                className="btn btn-light dropdown-toggle d-flex align-items-center"
                type="button"
                id="userDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src={user?.avatar || 'https://via.placeholder.com/30'}
                  alt="User Avatar"
                  className="rounded-circle me-2"
                  width="30"
                  height="30"
                />
                <span className="text-success">{user?.name || 'User'}</span>
              </button>

              <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                
                <li>
                  <button className="dropdown-item" onClick={handleLogout}>
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default DashboardNavbar;
