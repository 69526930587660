import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import DashboardNavbar from '../../components/DashboardNavbar';
import Sidebar from '../../components/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import UserModal from '../../components/UserModal'

const ViewUsers = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      const token = localStorage.getItem('token'); 
      const apiUrl = process.env.REACT_APP_API_URL;

      try {
        const response = await axios.get(`${apiUrl}/users`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsers(response.data);
      } catch (err) {
        setError('Failed to fetch users.');
      }
    };

    fetchUsers();
  }, []);



  const refreshUsers = async () => {
    const token = localStorage.getItem('token'); 
    const apiUrl = process.env.REACT_APP_API_URL;

    try {
      const response = await axios.get(`${apiUrl}/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data);
    } catch (err) {
      setError('Failed to fetch users.');
    }
  };

  const deleteUser = async (userId) => {
    const token = localStorage.getItem('token');
    try {
      await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await axios.delete(`${process.env.REACT_APP_API_URL}/users/${userId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          Swal.fire('Deleted!', 'User has been deleted.', 'success');
          refreshUsers(); // Refresh the users list
        }
      });
    } catch (err) {
      Swal.fire('Error', 'Failed to delete the user.', 'error');
    }
  };

  const handleAddUser = () => {
    setCurrentUser(null); // Reset user for adding
    setShowModal(true); // Show modal for adding user
  };

  const handleEditUser = (user) => {
    setCurrentUser(user); // Set current user for editing
    setShowModal(true); // Show modal for editing user
  };

  return (
    <div>
      <DashboardNavbar />
      <div className="d-flex">
        <Sidebar />
        <div className="main-content p-4" style={{ width: "85%" }}>
          <h4 className="mb-4">Users</h4>
          <button className='btn btn-success float-end' onClick={handleAddUser}> 
            <i className="bi bi-person-plus"></i> Add User 
          </button>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Role</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={user.id}>
                  <th scope="row">{index + 1}</th>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-warning me-2"
                      onClick={() => handleEditUser(user)}
                    >
                      <FontAwesomeIcon icon={faEdit} /> Edit
                    </button>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => deleteUser(user.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} /> Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Include the UserModal component here */}
          <UserModal 
            showModal={showModal} 
            onClose={() => setShowModal(false)} 
            userData={currentUser} 
            onSave={refreshUsers} 
          />
        </div>
      </div>
    </div>
  );
};

export default ViewUsers;
