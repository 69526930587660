import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Card.css'; 

const Card = ({ title, value, icon }) => {
  return (
    <div className="card-number shadow-sm bg-light p-4 d-flex align-items-center">
      <div className="card-icon-number">
        <FontAwesomeIcon icon={icon} size="2x" />
      </div>
      <div className="card-content-number ms-3">
        <h4 className="card-title-number">{title}</h4>
        <p className="card-value-number">{value}</p>
      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired, // FontAwesomeIcon or similar
};

export default Card;
