import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import DashboardNavbar from '../../components/DashboardNavbar';
import Sidebar from '../../components/Sidebar';

const Settings = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleChangePassword = async (e) => {
    e.preventDefault();

    // Validate password fields
    if (newPassword !== confirmPassword) {
      Swal.fire('Error', 'New password and confirmation do not match.', 'error');
      return;
    }

    const token = localStorage.getItem('token');
    const apiUrl = process.env.REACT_APP_API_URL;

    try {
      await axios.post(
        `${apiUrl}/change-password`,
        {
          oldPassword,
          newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Attach token for authentication
          },
        }
      );

      Swal.fire('Success', 'Password updated successfully!', 'success');
      // Clear fields
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      Swal.fire('Error', error.response?.data?.error || 'Failed to update password.', 'error');
    }
  };

  return (
    <div>
      <DashboardNavbar />
      <div className="d-flex">
        <Sidebar />
        <div className="main-content p-4" style={{ width: '85%' }}>
          <h4 className="mb-4">Settings</h4>
          <form onSubmit={handleChangePassword}>
            <div className="mb-3 col-4">
              <label htmlFor="oldPassword" className="form-label">Old Password</label>
              <input
                type="password"
                className="form-control"
                id="oldPassword"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
              />
            </div>
            <div className="mb-3 col-4">
              <label htmlFor="newPassword" className="form-label">New Password</label>
              <input
                type="password"
                className="form-control"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="mb-3 col-4">
              <label htmlFor="confirmPassword" className="form-label">Confirm New Password</label>
              <input
                type="password"
                className="form-control"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-success">Change Password</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Settings;
